import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import nextI18nextConfig from 'next-i18next.config'
import { Redirect } from 'lib/redirect'

export default function Index() {
  const router = useRouter()
  const currentLocale =
    router.query.locale || nextI18nextConfig.i18n.defaultLocale

  useEffect(() => {
    document.documentElement.lang = currentLocale.toString()
  }, [currentLocale])

  Redirect()

  return (
    <Head>
      <title>KROEMTECH GmbH | Industrielle IoT-Lösungen</title>
      <meta
        name="description"
        content="Experten für Sensor-Cloud-Kommunikation für industrielle Anwendungen."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="content-language" content="de" />
      <link rel="shortcut icon" type="image/x-icon" href="favicon.ico?" />
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />
      <link rel="alternate" hrefLang="de" href="https://www.kroemtech.ch/" />
      <link
        rel="alternate"
        hrefLang="x-default"
        href="https://www.kroemtech.ch/"
      />
    </Head>
  )
}

/*
className = {`${styles.main} ${inter.className}`
*/
