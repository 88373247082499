import { useEffect } from 'react'
import { useRouter } from 'next/router'
import languageDetector from './languageDetector'

export const useRedirect = To => {
  const router = useRouter()
  const to = To || router.asPath

  // language detection
  useEffect(() => {
    const detectedLng = languageDetector.detect()

    const route = router.route

    if (to.startsWith(`/${detectedLng}`) && router.route === '/404') {
      // prevent endless loop
      router.replace(`/${detectedLng}${route}`)
      return
    }

    languageDetector.cache(detectedLng)
    router.replace(`/${detectedLng}${route}`)
  })

  return null
}

export function Redirect() {
  useRedirect()
  return null
}

export const getRedirect = to =>
  function () {
    useRedirect(to)
    return null // <></>
  }
